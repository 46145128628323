import { ref } from 'vue'
import Api from '../../../../../services/getPermissionsDataTable'

const dataList = ref([])
const getDataList = async () => {
  const { result } = await Api()
  dataList.value = result ?? []
}

const useDataTable = () => {
  return {
    dataList,
    getDataList
  }
}
export {
  useDataTable
}
