<template>
  <Toast />
  <Dialog v-model:visible="display" :breakpoints="{'1200px':'70vw','992px':'80vw'}" :style="{width: '60vw'}">
    <template #header>
      <h3 v-if="bandera === 0">Nuevo registro</h3>
      <h3 v-else>Actualizar registro</h3>
    </template>
    <form action="">
      <div class="card">
        <DataTable
          :value="listDataTable"
          v-model:selection="selectedDataTable"
          dataKey="id"
          responsiveLayout="scroll"
          :scrollable="true"
          scrollHeight="300px"
          class="p-datatable-sm"
        >
          <template #header>
            <div class="p-field">
              <label for="fieldId">Usuarios del sistema</label>
              <Dropdown
                v-model="modelForm.userId"
                :options="peopleList"
                optionLabel="full_name"
                optionValue="userId"
                :filter="true"
                placeholder="seleccione"
                :showClear="true"
                style="display: flex;"
              />
              <span class="" style="color: darkred">
                {{ errors.userId }}
              </span>
            </div>
          </template>
          <template #empty>
            No customers found.
          </template>
          <template #loading>
            Loading customers data. Please wait.
          </template>
          <Column selectionMode="multiple"  style="width:10%"/>
          <Column field="name" header="Permiso" style="width:40%"/>
          <Column field="description" header="Descripción" style="width:50%" />
        </DataTable>
      </div>
    </form>
    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeModal" />
      <Button label="Confirmar" icon="pi pi-check" autofocus  @click="handleRegister"/>
    </template>
  </Dialog>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useField, useForm, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { useToast } from 'primevue/usetoast'
import { useDataTable } from '../dataTable/useDataTable'
import getAllPermissions from '@/apps/pharmasan/accounting/amortizations/services/getAllPermissions'
import getCommonPeopleList from '@/apps/pharmasan/accounting/amortizations/services/getCommonPeopleList'
import createPermissions from '@/apps/pharmasan/accounting/amortizations/services/createPermissions'
export default {
  name: 'modalNotifications',
  setup () {
    const { getDataList } = useDataTable()
    const toast = useToast()
    // schema
      const validationSchema = yup.object({
        userId: yup.number().required().label('Usuario')
      })
    // usefield
      useField('userId', null, { initialValue: null })
    // use form
    const { errors, handleSubmit, values: modelForm, handleReset } = useForm({ validationSchema })
    // data
      const display = ref(false)
      const bandera = ref(0)
      const listDataTable = ref([])
      const selectedDataTable = ref([])
      const peopleList = ref([])
    // hooks
    onMounted(() => {
      apiAllPermissions()
      apiCommonPeopleList()
      setTimeout(handleReset, 1000)
    })
    // methods
      const toggle = (value) => {
        bandera.value = value
        display.value = !display.value
      }
      const closeModal = () => {
        handleReset()
        display.value = false
      }
      const apiAllPermissions = async () => {
        const { result } = await getAllPermissions()
        listDataTable.value = result ?? []
      }
      const apiCommonPeopleList = async () => {
      const { result } = await getCommonPeopleList()
      peopleList.value = result ?? []
    }
      const handleRegister = handleSubmit((form) => {
        const data = {}
        for (const [key, value] of Object.entries(form)) {
          data[key] = value
        }
        const modelPermissionsActives = selectedDataTable.value.map(({ action }) => {
          return {
            permission_action: action,
            state: true,
            type: 'SecurityUsers',
            base: data.userId
          }
        })
        apiRegisterPermissions(modelPermissionsActives)
      })
      const apiRegisterPermissions = async (model) => {
      const { data } = await createPermissions(model)
      if (data) {
        toast.add({ severity: 'success', summary: 'Permisisos', detail: 'Creados con exíto', life: 3000 })
        closeModal()
        await getDataList()
      }
    }
    // return
      return {
        toggle,
        display,
        bandera,
        closeModal,
        listDataTable,
        selectedDataTable,
        modelForm,
        peopleList,
        handleRegister,
        errors,
        ErrorMessage
      }
  }
}
</script>
<style lang="css" scoped >
::v-deep(.p-datatable-scrollable .p-datatable-thead > tr > th){
  display: flex;
  align-items: center;
  flex: auto;
}
::v-deep(.p-datatable-scrollable .p-datatable-tbody > tr > td) {
  display: flex;
  flex: auto;
  align-items: center;
}
</style>
